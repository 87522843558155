$(document).ready(function() {
    if($('.contactLogoGroup').length) {
        $('.contactLogoGroup').matchHeight({
            byRow: false
        });
    }

    if($('.filter-containers').length > 0) {
        $('.filter-containers').matchHeight();
    }
});
