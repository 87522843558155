/*----------  Global Variables  ----------*/

let wWidth;
let wHeight;

/*----------  Document Ready  ----------*/
initNProgress();
$(document).ready(function() {
	wWidth = $(window).width();
	wHeight = $(window).height();

    console.log('Ready!');

    // initButter();
    // initAnimsition();
    // hidePreloader();
    fnGroupLinksFunction()
    fn_nav_toggle();
    fn_toggle();
    initParallax();
    initSmoothScroll();

    // $('.modal').on('hidden.bs.modal', function(e) {
    //     console.log('Modal Hidden');
    //     setTimeout(function() {
    //         initButter();
    //     }, 1000);
    // });
});

/*----------  Window Events  ----------*/

$(window).on('resize', function() {
	wWidth = $(window).width();
    wHeight = $(window).height();

    console.log('Window Resize');
    // initButter();
});

$(window).on('load', function(){
    wWidth = $(window).width();
	wHeight = $(window).height();
    hidePreloader();
    initWow();
});

function initNProgress() {
    NProgress.configure({
        trickleSpeed: 50,
        showSpinner: false
    });
    NProgress.start();
}

function stopNProgress() {
    NProgress.done(true);
}

function initAnimsition() {
    $('.animsition').animsition({
        loading: false,
        inDuration: 500,
        outDuration: 500,
        timeout: true,
        timeoutCountdown: 1000,
    });
}

function initParallax() {
    new simpleParallax(document.getElementsByClassName('rellax-fast'), {
        overflow: true,
        scale: 2,
        delay: 1
    });

    new simpleParallax(document.getElementsByClassName('rellax-mid'), {
        overflow: true,
        scale: 1.8,
        delay: 1
    });

    new simpleParallax(document.getElementsByClassName('rellax-slow'), {
        overflow: true,
        scale: 1.4,
        delay: 1
    });
}

function initButter() {
    // Scrollbar.initAll();
    var ios = iOS();
    console.log(ios);
    
    if(ios == false){
       
        if(wWidth > 991 && $('#butterWrapper').length) {
            butter.init({
                wrapperId: 'butterWrapper',
                // cancelOnTouch: true,
                wrapperDamper:0.1
            });
        }    
    }    
}

function hidePreloader() {
    $('#preloader img').fadeOut('slow', function(){
        stopNProgress();
        $('#preloader').fadeOut('slow', function() {
            initButter();
            // initWow();
        });
    });
}

function initWow() {
    new WOW().init();
}

function initLocationMap(lat, lng, markerTitle, elementId) {
	var latLng = {lat: lat, lng: lng};

	var map = new google.maps.Map(document.getElementById(elementId), {
		zoom: 13,
        center: latLng,
        styles: [{ "stylers": [ { "hue": "#2c3e50" }, { "saturation": 250 } ] }, { "featureType": "road", "elementType": "geometry", "stylers": [ { "lightness": 50 }, { "visibility": "simplified" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "off" } ] } ],
        disableDefaultUI: true
	});

	var marker = new google.maps.Marker({
		position: latLng,
		map: map,
		title: markerTitle
	});
}

function initContactMap(lat, lng, markerTitle, elementId) {
	var latLng = {lat: lat, lng: lng};

	var map = new google.maps.Map(document.getElementById(elementId), {
		zoom: 13,
        center: latLng,
        styles: [{ "stylers": [ { "hue": "#2c3e50" }, { "saturation": 250 } ] }, { "featureType": "road", "elementType": "geometry", "stylers": [ { "lightness": 50 }, { "visibility": "simplified" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "off" } ] } ],
        disableDefaultUI: true
    });
    console.log('Hello');

    setTimeout(function() {
        if (wWidth > 991) {
            console.log('Hello1');
            var marker = new google.maps.Marker({
                position: latLng,
                map: map,
                title: markerTitle
            });
        }
    }, 1000);
}

function initSmoothScroll()
{
    $('.smooth-scroll').on('click', function(event) {
        event.preventDefault();
        $('body, html').animate({
            scrollTop: $($(this).attr('href')).offset().top
        }, 500);
    });

    $('.smoothScroll').on('click', function(event) {
        event.preventDefault();
        console.log($(this).data('scrolltarget'));
        $('body, html').animate({
            scrollTop: $($(this).data('scrolltarget')).offset().top
        }, 500);
    });
}

function fnGroupLinksFunction() {
	$('.groupToggler').mouseenter(function() {
		$(this).closest('.groups-section-wrapper').addClass('groups-blurred');
        $(this).closest('.groups-section-wrapper').find('.groups-section-container-bg').css('background-image', "url(" + $(this).data('bg') + ")");
		$($(this).data('target-group')).css('opacity', '0');
		$($(this).data('target')).css('opacity', '1');
		$('.groupToggler').removeClass('active');
        $(this).addClass('active');

        // if(wWidth > 767) {
            var dividerHeight = $(this).closest('.groups-section-body').find('.divider').outerHeight();
            var top = "50%";
            var bottom = (dividerHeight - $(this).position().top);
            if ($(this).position().top < (dividerHeight/2)) {
                top = $(this).position().top;
                bottom = "50%";
            }
            $(this).closest('.groups-section-body').find('.divider-selected').css({
                top: top,
                opacity: "1",
                bottom: bottom
            });
        // }
	});

	$('.groupToggler').mouseleave(function() {
		$(this).closest('.groups-section-wrapper').removeClass('groups-blurred');
		$($(this).data('target-group')).css('opacity', '0');
		$('.groupDetail.default').css('opacity', '1');
        $('.groupToggler').removeClass('active');

        // if(wWidth > 767) {
            $(this).closest('.groups-section-body').find('.divider-selected').css({
                top: "auto",
                bottom: "auto"
            });
        // }
	});
}


function fn_nav_toggle()
{
    var target;

    var $toggler = $('[data-nav-toggle]');

    if($toggler.length > 0)
    {
        $toggler.off('click.toggle').on('click.toggle', function()
        {
            target = $(this).data('nav-toggle');
            var $target = $(target).first();

            if($target.length > 0)
            {
                if($target.hasClass('show'))
                {
                    $target.removeClass('show');
                    $target.closest('header').find('.group-header').removeClass('show');
                    $('body').css('overflow', 'auto');
                    $(this).removeClass('open');
                }
                else
                {
                    $target.addClass('show');
                    $target.closest('header').find('.group-header').addClass('show');
                    $('body').css('overflow', 'hidden');
                    $(this).addClass('open');
                }
            }

            return false;
        });
    }
}

function fn_toggle()
{
    var target;

    var $toggler = $('[data-fn-toggle]');

    if($toggler.length > 0)
    {
        $toggler.off('click.toggle').on('click.toggle', function()
        {
            target = $(this).data('fn-toggle');
            var $target = $(target).first();

            if($target.length > 0)
            {
                if($target.is(':visible'))
                {
                    $target.slideUp('fast');
                    $(this).removeClass('open');
                }
                else
                {
                    $target.slideDown('fast');
                    $(this).addClass('open');
                }
            }

            return false;
        });
    }
}

function iOS() {
	return [
	  'iPad Simulator',
	  'iPhone Simulator',
	  'iPod Simulator',
	  'iPad',
	  'iPhone',
	  'iPod'
	].includes(navigator.platform)
	// iPad on iOS 13 detection
	|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
