negotiation = "";
$(document).ready(function() {
    negotiation = ($('#purpose').length && $('#purpose').val().length) ? $('#purpose').val() : '';
	initFavIcon();
	initFavbadge();
});

function initFavbadge() {
	if ($('.favbadge').length) {
		var favouriteEstates = (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
		$('.favbadge').html(favouriteEstates.length);
		$('.favbadge').on('refresh', function() {
			console.log("Fav badge Refresh");
			var favouriteEstates = (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
			$(this).html(favouriteEstates.length);
		});
	}
}

function initFavIcon() {
	if ($('.devFavEstate').length) {
		$('.devFavEstate').off('click').on('click', function(e){
			e.preventDefault();
			console.log($(this).data('estateid'));
			var estateId = $(this).data('estateid') + "";
			if ($(this).hasClass('active')) {
				removeFavouriteEstate(estateId);
			} else if (!$(this).hasClass('active')) {
				addFavouriteEstate(estateId);
			}
			$(this).toggleClass('active');

			$('.favbadge').length ? $('.favbadge').trigger('refresh') : true;
		});
	}
}

function addFavouriteEstate(estateId){
	var favouriteEstates = (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
	favouriteEstates.push(estateId);
	localStorage.setItem("favouriteEstates"+negotiation, JSON.stringify(favouriteEstates));
}

function removeFavouriteEstate(estateId){
	var favouriteEstates = (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
	if (favouriteEstates.includes(estateId)) {
		favouriteEstates.splice( favouriteEstates.indexOf(estateId), 1 );
	}
	localStorage.setItem("favouriteEstates"+negotiation, JSON.stringify(favouriteEstates));
}

function isFavouriteEstate(estateId){
    estateId = estateId + "";
    var favouriteEstates = (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
	if (favouriteEstates.includes(estateId)) {
		return true;
	}
	return false;
}

function getFavouriteEstates(){
	return (localStorage.getItem('favouriteEstates'+negotiation) && localStorage.getItem('favouriteEstates'+negotiation).length > 0) ? JSON.parse(localStorage.getItem('favouriteEstates'+negotiation)) : [];
}
