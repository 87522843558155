$(document).ready(function () {

    initSelect2();

    initSelectLocation();

    initBootstrapSelectPicker();

    if (wWidth >= 992) {
        initRoomRangerSlider();
    } else {
        initRoomInputSpinner();
    }

});

function initRoomRangerSlider() {

    if($('#roomsRangeSlider').length) {
        var slider = document.getElementById('roomsRangeSlider');
        noUiSlider.create(slider, {
            start: [1, 5],
            step: 1,
            connect: true,
            tooltips: true,
            format: wNumb({
                decimals: 0
            }),
            range: {
                'min': 0,
                'max': 6
            }
        });
    }
}

function initRoomInputSpinner() {
    if($('.roomInputSpinner'.length)) {
        $('.roomInputSpinner').inputSpinner();
    }
}

function initSelect2() {
    if($('.select2').length) {
        $('.select2').select2({
            minimumResultsForSearch: Infinity
        });
    }
}

function initBootstrapSelectPicker() {
    if($('.bootstrapSelectPicker').length) {
        $('.bootstrapSelectPicker').selectpicker();
    }
}

function initSelectLocation() {
    if($('.select2-location').length) {

        var $select2Location = $('.select2-location');
        initSelectPlaceholder($select2Location, "Entrez les communes de votre choix");
        var select2LocationContainer = $('.select2-location').data('containerclass');

        // console.log(select2LocationContainer);
        $select2Location.select2({
            minimumResultsForSearch: Infinity,
            containerCssClass: select2LocationContainer,
            placeholder: "Entrez les communes de votre choix"
        });

        $select2Location.select2().on('select2:open', function() {
            setTimeout(function() {
                var container = $('.select2-container').last();
                container.css('top', ($('.select2-search--inline').offset().top + $('.select2-search--inline').outerHeight()));
            }, 1);
        });

        $select2Location.select2().on('select2:close', function() {
            initSelectPlaceholder($select2Location, "Entrez les communes de votre choix");
            initSelect2SearchInputEvent();
        });
        setTimeout(function() {
            initSelect2SearchInputEvent();
        }, 500);
    }

    if($('.select2-location-light').length) {

        var $select2LocationLight = $('.select2-location-light');
        initSelectPlaceholder($select2LocationLight, 'Localisation');
        var select2LocationContainer = $('.select2-location-light').data('containerclass');

        // console.log(select2LocationContainer);
        $select2LocationLight.select2({
            minimumResultsForSearch: Infinity,
            dropdownCssClass: select2LocationContainer,
            placeholder: "Entrez les communes de votre choix"
        });

        $($select2LocationLight.data('select2').$container).addClass(select2LocationContainer);

        $select2LocationLight.select2().on('select2:open', function() {
            setTimeout(function() {
                var container = $('.select2-container').last();
                container.css('top', ($select2LocationLight.next('.select2-container').find('.select2-search--inline').offset().top + $select2LocationLight.next('.select2-container').find('.select2-search--inline').outerHeight()));
            }, 1);
        });

        $select2LocationLight.select2().on('select2:close', function() {
            initSelectPlaceholder($select2LocationLight, 'Localisation');
        });
    }
}

function initSelect2SearchInputEvent() {
    // setTimeout(function() {
    //     $('.select2-search__field').on('keydown', function (e) {
    //         console.log($(this).val().length);
    //         console.log($(this).val());
    //         if (e.keyCode === 8 && $(this).val().length > 0) {
    //             console.log('Hello');
    //             // return false;
    //         } else {
    //             console.log('Hello1');
    //             return true;
    //         }
    //     });
    // }, 100);
}

function initSelectPlaceholder(select2, placeholder) {
    setTimeout(function() {
        select2.next('.select2').find('.select2-search--inline .select2-search__field').attr("placeholder", placeholder);
        select2.next('.select2').find('.select2-search--inline .select2-search__field').attr("autocomplete", "new-password");
    }, 50);
}
