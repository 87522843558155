$(document).ready(function () {
    initProjectMediaSlider();
});

function initProjectMediaSlider() {
	if($('.projectMediaSlider').length) {
		$('.projectMediaSlider').slick({
			arrows: false,
			dots: true,
			autoplay: true,
			customPaging : function(slider, i) {
			    return '<span></span>';
			}
		});
	}
}