$(document).ready(function() {
	$('.propertySlider').slick({
		autoPlay: true,
        autoPlaySpeed: 2000,
        adaptiveHeight: true,
		prevArrow: '<div class="slide-arrow slide-arrow-left"><i class="fas fa-chevron-left"></i></div>',
		nextArrow: '<div class="slide-arrow slide-arrow-right"><i class="fas fa-chevron-right"></i></div>'
	});

	// initPropertyLocationMap();
});

function initPropertyLocationMap(lat, lng, markerTitle, elementId) {
	var latLng = {lat: lat, lng: lng};

	var map = new google.maps.Map(document.getElementById(elementId), {
		zoom: 13,
        center: latLng,
        styles: [{ "stylers": [ { "hue": "#2c3e50" }, { "saturation": 250 } ] }, { "featureType": "road", "elementType": "geometry", "stylers": [ { "lightness": 50 }, { "visibility": "simplified" } ] }, { "featureType": "road", "elementType": "labels", "stylers": [ { "visibility": "off" } ] } ],
        disableDefaultUI: true
	});

	var marker = new google.maps.Marker({
		position: latLng,
		map: map,
		title: markerTitle
	});
}
